import _ from 'lodash';
import parse from 'html-react-parser';
import { useEffect, useMemo, useRef, useState } from 'react';

import touchpadSVG from './assets/images/SVG/touchpad.svg';
import pointerSVG from './assets/images/SVG/pointer.svg';
import arrowSVG from './assets/images/SVG/arrow.svg';


const slideDuration = 15000;
const inactiveDuration = 5200;
const instanceDuration = 1900;

// const marginYpercentage = 6.2;
// const marginXpercentage = 6.2;
const marginYpercentage = 3.8;
const marginXpercentage = 3.8;

const INSTANCES = [
  { 'serf': 100, 'wght': 100 }, // Regular
  { 'serf': 100, 'wght': 300 }, // ULt Sans
  { 'serf': 100, 'wght': 400 }, // Lt Sans
  { 'serf': 100, 'wght': 600 }, // Rg Sans
  { 'serf': 100, 'wght': 800 }, // Bd Sans
  { 'serf': 100, 'wght': 900 }, // Bk Sans
  { 'serf': 600, 'wght': 300 }, // ULt Mix
  { 'serf': 600, 'wght': 400 }, // Lt Mix
  { 'serf': 600, 'wght': 600 }, // Rg Mix
  { 'serf': 600, 'wght': 800 }, // Bd Mix
  { 'serf': 600, 'wght': 950 }, // Bk Mix
  { 'serf': 1000, 'wght': 300 }, // ULt Serif
  { 'serf': 1000, 'wght': 400 }, // Lt Serif
  { 'serf': 1000, 'wght': 600 }, // Rg Serif
  { 'serf': 1000, 'wght': 800 }, // Bd Serif
  { 'serf': 1000, 'wght': 1000 }, // Bk Serif
]

const sentences = [
  { text: 'אינסוף || נקודות ביניים || מאז ועד היום, || כל שנשאר || הוא להחליט || היכן תרצו || לעצור', size: 'm' },
  { text: 'א', size: 'xxl' },
  { text: 'טיפוסי האות האחרונים הופיעו || כאמור בסמוך לקום המדינה והם || תוצאה של הדחף הפנימי של || יוצריהם, כל אחד בפינה שלו. || אי־אפשר להימנע מהקשר הסמוי || בין תנופת ההתחדשות של || הטיפוגרפיה העברית והתחדשות || עצמאותו של עם ישראל במולדתו.', size: 's' },
  { text: 'ת', size: 'xxl' },
  { text: 'נעים על ציר || הזמן, מישן || ועד חדש || מקלאסי || למעודכן' },
  { text: 'התחלה, ||  אמצע || וסוף.', size: 'l' },
  { text: 'ב', size: 'xxl' },
  { text: 'שלושה עקרונות הנחתי ביסוד מפעלי, || בבואי לצור צורה חדשה לגמרי: שמירת || אופייה המקורי של האות העברית, || בהסתמך על דוגמותיה הקודמות ככל || האפשר; בהירותה המקסימלית של || צורת האותיות ונוחותן לקריאה, || בהסתמך על הישגי מדע האופטיקה; || מתן צורה, שיהא סגנונה הולם את || מושגיה המודרניים של האסטתיקה. ||  || — אליהו קורן', size: 'xs' },


  // { text: 'הרעיון || המרכזי הוא || האפשרות || לייצר מידע || חדש מאוסף || נתונים סופי.' },
  // { text: 'משפט נחמד || שהיה כאן || ופתאום הלך...' },
  // { text: 'הנה עוד || אחד נחמד. || איזה יופי || חה חה אורי || מה דעתך' },
  // { text: `רבקה קרמר (נולדה ב-31 במאי 1930) היא זמרת ישראלית, שהייתה זמרת וסולנית בצ'יזבטרון. רבקה קרמררבקל'ה מדניק עם בנות הצ'יזבטרון (מרגלית בנאי משמאל ונעמי רבקה קרמר נולדה בליטא בשם רבקה מדניק למיכאל וברינה. בשנת 1933 עלתה לארץ ישראל יחד עם משפחתה, בגיל שלוש וחצי, במסגרת העלייה החמישית. קרמר גדלה בתל אביב בשנת 1947 התגייסה קרמר לפלמ"ח ושירתה כפקידה במחלקת ההסברה תחת זרובבל גלעד (לצידה של לאה רבין). `, size: 's' },
];






function App() {



  // Add event listeners
  useEffect(() => {
    window.addEventListener("keydown", downHandler);

    return () => {
      window.removeEventListener('keydown', downHandler);
    };
  }, [downHandler]);

  const [darkMode, setDarkMode] = useState(false);

  function downHandler(e) {
    console.log('keydown e', e);
    if (e.code === "KeyD") {
      console.log('darkMode', darkMode);
      setDarkMode(!darkMode);
    }
  }



  const instances = useMemo(() => _.shuffle(INSTANCES), [INSTANCES]);



  const [currentSlide, setCurrentSlide] = useState(0);

  const [axes, setAxes] = useState({ weight: 0, serif: 0 });


  function nextSlide() {
    const nextSlide = currentSlide + 1;
    if (sentences[nextSlide])
      setCurrentSlide(nextSlide);
    else
      setCurrentSlide(0);
  }

  const [cursorPosition, setCursorPosition] = useState({ top: 0, left: 0 });


  const lastUpdateCall = useRef();


  // function move(e) {
  //   moveOnce(e);
  //   // if (lastUpdateCall.current) {
  //   //   console.log('lastUpdateCall.current', lastUpdateCall.current);
  //   //   cancelAnimationFrame(lastUpdateCall.current); // if an animation frame was already requested after last repaint, cancel it in favour of the newer event
  //   //   console.log('cancel lastUpdateCall.current', lastUpdateCall.current);
  //   // }
  //   // lastUpdateCall.current = requestAnimationFrame(() => moveOnce(e));
  //   // console.log('- SCHEDULE:', lastUpdateCall.current);
  // }


  const marginXpercentageConvertedToY = useMemo(() => marginXpercentage / window.innerWidth * window.innerHeight, [window.innerWidth, window.innerHeight])

  // const [isMoving, setIsMoving] = useState(true)

  // useEffect(() => {
  //   if (isMoving) window.addEventListener("mousemove", move);
  //   return () => window.removeEventListener("mousemove", move);
  // }, [isMoving]);


  function move(e) {

    // console.log(`move(e)`, e);


    // requestAnimationFrame(() => {

    let X, Y;
    if (e.type === 'touchmove') {
      X = e.changedTouches[0].clientX;
      Y = e.changedTouches[0].clientY;
    } else {
      X = e.clientX;
      Y = e.clientY;
    }



    const mouseXpercentage = X / window.innerWidth * 100;
    const mouseYpercentage = Y / window.innerHeight * 100;

    // used for actual font axes and the public cursor axes
    const computedYPercentage = Math.min(Math.max((mouseYpercentage / 100 * (100 + marginYpercentage * 2)) - marginYpercentage, 0), 100);
    const computedXPercentage =
      Math.min(Math.max(
        (mouseXpercentage - marginXpercentageConvertedToY) * 100 / (100 - marginXpercentageConvertedToY * 2)
        , 0), 100)
    const x = computedXPercentage / 100 * (100 - marginXpercentageConvertedToY * 2);
    const y = computedYPercentage / 100 * (100 - marginYpercentage * 2);







    const minAxe = 100;
    const maxAxe = 1000;

    // private / actual font axes
    const serf = ((maxAxe - minAxe) / 100) * computedYPercentage + minAxe;
    const wght = ((maxAxe - minAxe) / 100) * (100 - computedXPercentage) + minAxe;

    // public / cursor axes
    const serif = ((900 - minAxe) / 100) * computedYPercentage + minAxe;
    const weight = ((900 - minAxe) / 100) * (100 - computedXPercentage) + minAxe;


    // Better performence, maybe?
    if (lastUpdateCall.current) {
      cancelAnimationFrame(lastUpdateCall.current);
    }
    lastUpdateCall.current =
      requestAnimationFrame(() => {
        setInteractiveMode(true);

        setCursorPosition({ x: x, y: y })

        setAxes({
          serf: serf,
          wght: wght,
          serif: serif,
          weight: weight
        });
      });



    // });
  }


  useEffect(() => {
    const timer = setTimeout(() => nextSlide(), slideDuration);
    return () => clearTimeout(timer);
  }, [currentSlide]);




  function backToInactive() {
    setInteractiveMode(false);
  }

  useEffect(() => {
    const timer = setTimeout(() => backToInactive(), inactiveDuration);
    return () => clearTimeout(timer);
  }, [axes]);

  const [interactiveMode, setInteractiveMode] = useState(false);


  const [instanceIndex, setInstanceIndex] = useState(0);

  function nextInstance() {
    const next = instanceIndex + 1;
    if (instances[next])
      setInstanceIndex(next);
    else
      setInstanceIndex(0);
  }

  function prevInstance() {
    const prev = instanceIndex - 1;
    if (instances[prev]) {
      setInstanceIndex(prev);
      // console.log('has prev');
    } else {
      setInstanceIndex(instances.length);
      // console.log('no prev, restart', instances.length)
    }
  }

  function wheel(e) {
    if (e.deltaY > 0 || e.deltaX > 0) {
      nextInstance();
    } else {
      prevInstance();
    }
  }

  useEffect(() => {
    if (instances[instanceIndex]) {
      setAxes({
        serf: instances[instanceIndex].serf,
        wght: instances[instanceIndex].wght,
        serif: ((instances[instanceIndex].serf - 100) / 1000 * 900) + 100,
        weight: ((instances[instanceIndex].wght - 100) / 1000 * 900) + 100
      });
    }

  }, [instanceIndex])

  useEffect(() => {
    if (!interactiveMode) {
      const timer = setTimeout(() => nextInstance(), instanceDuration);
      return () => clearTimeout(timer);
    }
  }, [instanceIndex, interactiveMode])

  // useEffect(() => {

  //   setInterval(() => {
  //     nextInstance();
  //   }, instanceDuration);
  // }, [])

  return (
    <div
      className={`home  ${interactiveMode ? 'interactive-mode' : 'inactive-mode'} ${darkMode ? 'dark-mode' : 'light-mode'}`}
      onMouseDown={nextSlide}
      onMouseMove={move}
      onTouchMove={move}
      onWheel={e => wheel(e)}
    >
      <div className="inside">

        <div className="dotted-grid">
          {_.times((17 - 1) * (9 - 1), i => <span className="dot" key={i}><span className="last-row"></span><span className="last-last"></span></span>)}
        </div>

        <div className="axe-labels">
          <div className="label top-left">900, 100<br />שחור</div>
          <div className="label top-center">600, 100<br />בינוני</div>
          <div className="label top-right">100, 100<br />סנס קל</div>
          <div className="label right-center">100, 600<br />סלאב סריף</div>
          <div className="label right-bottom">100, 900<br />סריף</div>
        </div>

        <div className="sentences" style={{ fontVariationSettings: `'wght' ${axes?.wght}, 'serf' ${axes?.serf}` }}>
          {sentences.map((sentence, i) =>
            currentSlide === i || currentSlide === i + 1 ?
              <div className={`sentence ${currentSlide === i ? 'active' : 'hidden'} ${sentence?.size ? sentence?.size : ''}`} key={i}>
                <div className="inside">
                  {parse(
                    sentence.text
                      .replace(/ +(?= )/g, '') // remove all double spaces
                      .split(' ').map(i => `<span class="word">${i.trim()}</span>`).join(' ') // wrap with span.word
                      .split(`<span class="word">||</span>`).map(i => i.trim()).join("<br />") // create breaks
                  )}
                </div>
              </div>
              : ''
          )
          }
        </div>


        <div className="touch-hint">

          <div className="inside">
            <img className="touchpad" src={touchpadSVG} alt="" />
            <img className="pointer" src={pointerSVG} alt="" />
            <img className="arrow" src={arrowSVG} alt="" />
          </div>
        </div>


        <div className="mouse-cursor"
          style={{ transform: `translate( ${cursorPosition.x}vw, ${cursorPosition.y}vh )` }}
        >
          <div className="inside">
            <div className="inside">
              {Math.round(axes?.weight)}<br />
              {Math.round(axes?.serif)}
            </div>
          </div>
        </div>

      </div>



    </ div>
  );
}








export default App;
